@import "src/css/variables.scss";

/* All */
.jetdai-activity-out-of-period {
  margin-top: 24px;
  font-size: 32px;
  font-weight: bold;
}
.jetdai-activity {
  background: #f2e6d3;
}
.jetdai-activity,
.jetdai-activity h3 {
  /* color: #fff; */
  color: #4D4D4D;
}
.jetdai-activity .activity-divider {
  /* color: #fff; */
  color: #4D4D4D;
}
.jetdai-activity-banner img {
  width: 100%;
}


/* Section1 */
.jetdai-activity-content img {
  width: 150px;
}
.jetdai-activity-content .title {
  margin-top: 10px;
  font-size: 22px;
}

.jetdai-small-text {
  font-size: 14px;
}


/* Section2 */
.jetdai-activity-gift img {
  height: 200px;
}
.jetdai-activity-gift .content-name {
  margin-top: 18px;
  font-size: 20px;
  font-weight: bold;
}
.jetdai-activity-gift .content-price {
  font-size: 16px;
  font-weight: bold;
}
.jetdai-activity .frontJetDaiProject {
  border: 2px solid #fff;
}

/* Section3 */
.jetdai-activity .step {
  /* border: 4px solid #4D4D4D; */
  border: 4px solid #fff;
  color: #fff;
}


/* Section4 */
.jetdai-faq {
  background-color: transparent !important;
}
.jetdai-faq .ant-collapse-item {
  border: 1px solid #4D4D4D !important;
  border-radius: 30px !important;
  margin-bottom: 10px;
  background-color: #fff;
}

.jetdai-faq .ant-collapse-content {
  border-bottom-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.jetdai-faq .ant-collapse-header {
  background-color: transparent !important;
  color: #4D4D4D !important;
}


@media (max-width: 1000px) {
  /* Section2 */
  .jetdai-activity-gift img{
    height: 150px;
  }
}

@media (max-width: 820px) {
  /* Section1 */
  .jetdai-activity-banner img {
    /* content: url("src/assets/jetdai-activity/background_pad.png"); */
  }
}

@media (max-width: 767px) {
  /* Section1 */
  .jetdai-activity-content img {
    width: 100px;
  }
}

@media (max-width: 600px) {
  /* Section1 */
  .jetdai-activity-banner {
    padding-top: 0 !important;
  }
  /* .jetdai-activity-banner img {
    content: url("src/assets/jetdai/jetdai-activity/background_mobile.png");
  } */
  .jetdai-activity .jetdai-activity-bg-text {
    font-size: 18px;
  }

}
