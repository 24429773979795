@import "src/css/variables.scss";

@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
.frontLayout {
  font-family: "Noto Sans TC";
}

/* front css component */
/* main style */
.frontTitleImage {
  height: 170px;
  margin: 0 0 20px 0;
  background-image: url("src/assets/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  text-align: left;
  line-height: 170px;
  padding-top: 20px;
}
.linearText {
  background: linear-gradient(to bottom, #EB664E, #EB7842, #F5C395);
  background: -webkit-linear-gradient(to bottom, #EB664E, #EB7842, #F5C395);
  font-size: 50px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.frontTitle {
  margin: 0 0 0 2em;
  font-size: 35px;
  font-weight: bold;
}
h1.front {
  color: var(--front-title);
  font-size: 40px;
  font-weight: bold;
}
h2.front {
  color: var(--front-title);
  font-size: 30px;
  font-weight: bold;
}
h2.front p {
  display: inline;
  margin: 0;
}
h3.front {
  font-size: 24px;
}
h4.front {
  font-size: 16px;
}
.highlight-text {
  color: var(--front-required);
}
.list-title {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  line-height: 28px;
  border: 1px var(--front-primary) solid;
  border-radius: 10px;
}

/* width, height */
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}

/* spacing */
.mt-none {
  margin-top: 0;
}
.mb-none {
  margin-bottom: 0;
}

/* text */
.text-white {
  color: white !important;
}
.text-black {
  color: #4D4D4D !important;
}
.pre-line {
  white-space: pre-line;
}

/* align */
.d-flex {
  display: flex;
}
.justify-start {
  justify-content: start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.flex-wrap {
  flex-wrap: wrap;
}

/* card style */
.frontCard {
  border-radius: 20px;
  box-shadow: -2px -2px 6px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1);;
}

/* button style */
button {
  cursor: pointer;
}
.frontBtn {
  width: 115px;
  height: 40px;
  font-size: 16px;
  background-color: var(--front-primary);
  color: white;
  border-radius: 50px;
  border: 4px white solid;
  box-shadow: -2px -2px 6px rgba(0, 0, 0, 0.2), 2px 2px 6px rgba(0, 0, 0, 0.2);
}
.frontBtn.orange {
  background-color: var(--front-secondary);
}
.frontBtn:hover {
  border: 2px white solid;
}
.frontBtn-transparent {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.2em;
  padding: 6px 24px;
  background-color: #fa9221;
  color: white;
  border-radius: 50px;
  border: 3px white solid;
  box-shadow: -2px 5px 0px rgba(255, 255, 255, 0.6);
}

/* floating button */
.btn-floating {
  position: fixed;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: auto;
  text-align: center;
  line-height: 60px;
  z-index: 100;
  box-shadow: 0 10px 10px -10px rgba(99, 99, 99, 0.5);
  text-decoration: none;
  border: none !important;
}
.btn-floating.line {
  bottom: 30px;
  right: 20px;
}
.btn-floating.jcoin {
  bottom: 110px;
  right: 20px;
}
.btn-floating img {
  width: 60px;
  position: absolute;
  right: 0;
}

/* modal style */
.front .ant-modal-content {
  border-radius: 30px;
}

/* tabs style */
.front-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.front-tab .ant-tabs-tab-btn:hover {
  color: #EB664E !important; 
}
.ant-tabs-tab-btn, .ant-collapse-header {
  font-size: 16px;
}
.front-tab .ant-tabs-ink-bar {
  background: #EB664E;
}
.ant-collapse {
  border-radius: 10px;
  border: none;
}
.ant-collapse-header {
  background-color: white;
}
.no-style-link,
.no-style-link:hover {
  color: inherit;
  text-decoration: none;
}
.disabled-link {
  pointer-events: none;
}

/* FAQ style */
.faq-expanded-item {
  text-align: left;
  margin-left: 2em;
}

@media (max-width: 600px) {
  .frontTitleImage {
    height: 120px;
    line-height: 120px;
  }
  .frontTitle {
    margin: 0 0 0 1em;
  }
  h1.front {
    font-size: 25px;
  }
  h2.front {
    font-size: 22px;
  }
  h3.front {
    font-size: 20px;
  }
  h2.front p {
    display: block;
  }
}